<template>
  <!-- app nav -->
  <AppNav v-if="appView">튜터링 X KB스타뱅킹</AppNav>

  <div class="promotion" :style="{ 'padding-top': appPadding }">
    <section class="main_section">
      <img v-img-lazy-loading :data-url="imgUrls.img_kbp_01" class="img_kbp_01" alt="img_kbp_01" />
      <img src="../../assets/kbstar/markCircle.png" class="markCircle" />
    </section>
    <section class="benefit_section">
      <img v-img-lazy-loading :data-url="imgUrls.img_kbp_02" class="img_kbp_02" alt="img_kbp_02" />
      <p class="title">튜터링 수강권</p>
      <p class="desc">최대 59% 할인!</p>
      <div class="benefit_card">
        <div class="benefit_card_label">혜택 1</div>
        <div class="benefit_card_desc">
          업계최저가 보장!
          <br />
          튜터링 상설특가제
          <b>최대 51% 할인</b>
        </div>
      </div>
      <div class="benefit_card">
        <div class="benefit_card_label">혜택 2</div>
        <div class="benefit_card_desc">
          최대 51% 할인가에 KB 제휴쿠폰
          <br />
          <b>20% 추가 할인!</b>
        </div>
      </div>
      <div class="benefit_card">
        <div class="benefit_card_label">혜택 3</div>
        <div class="benefit_card_desc">
          1:1회화 수업
          <br />
          <b>2회 추가 증정</b>
          (수량 한정)
        </div>
      </div>
    </section>
    <section class="event_section">
      <img v-img-lazy-loading :data-url="imgUrls.img_kbp_03" class="img_kbp_03" alt="img_kbp_03" />
      <div class="card">
        <div class="title">이벤트 기간</div>
        <div class="desc">2023.4.10(월) ~ 4.30(일)</div>
      </div>
      <div class="card">
        <div class="title">이벤트 대상</div>
        <div class="desc">KB스타뱅킹 로그인한 고객 누구나</div>
      </div>
      <div class="card">
        <div class="title">참여 방법</div>
        <div class="desc">
          1. KB 스타뱅킹 APP의 ‘이벤트' 메뉴 선택
          <br />
          2. 튜터링 이벤트의 ‘응모하기' 버튼 클릭
          <br />
          3. KB스타뱅킹 알림(Push)동의
        </div>
        <div class="desc_small" style="margin-left: 15px">*기존 알림 동의 고객 응모 가능</div>
      </div>
      <div class="card">
        <div class="title">참여 혜택</div>
        <div class="desc">열공 응원 쿠폰 2종</div>
        <div class="desc_small">*쿠폰은 매주 수요일 KB국민은행에 등록된 연락처로 발송될 예정입니다.</div>
      </div>
      <div class="coupon">
        <p class="desc">[응모 시 100%] 20% 할인 쿠폰 (최대 20만원)</p>
        <img v-img-lazy-loading :data-url="imgUrls.img_kbp_04" class="img_kbp_04" alt="img_kbp_04" />
        <p class="desc">[주차별 1만명 한정] 3만원 상당 무료체험권</p>
        <img v-img-lazy-loading :data-url="imgUrls.img_kbp_05" class="img_kbp_05" alt="img_kbp_05" />
        <button class="button" @click="downloadCoupon()">제휴 쿠폰 받기</button>
      </div>
      <div class="qr" v-if="!isMobile">
        <p>
          *PC로 접속하신 경우 아래 QR코드를 통해
          <br />
          KB스타뱅킹 앱 다운로드 후 쿠폰을 다운받아보세요.
        </p>
        <img src="../../assets/kbstar/qr.png" />
      </div>
    </section>
    <!-- 하단 유의 사항  -->
    <section class="notice_section">
      <div class="container">
        <p class="title">[쿠폰 유의사항]</p>
        <ul>
          <li class="list__item">
            할인 쿠폰 및 무료 체험권은 매주 수요일 KB국민은행에 등록된 연락처로 발송될 예정입니다.
          </li>
          <li class="list__item">
            할인 쿠폰 및 무료 체험권은 튜터링 앱 > 마이 튜터링 > 나의 쿠폰 메뉴에서 등록 후 사용 가능합니다.
          </li>
          <li class="list__item">
            할인 쿠폰 및 무료체험권 2023년 5월 7일까지 등록 가능하며, 등록 후 7일 이내 사용 가능합니다.
          </li>
          <li class="list__item">무료체험권은 ‘Global 튜터 7일 20분 2회 수강권’ 으로 제공됩니다.</li>
          <li class="list__item">
            할인쿠폰은 1개월 정기 결제 수강권 결제 시 사용할 수 없으며, 분할 사용 및 타 쿠폰과 중복사용이 불가합니다.
          </li>
          <li class="list__item">
            <u>쿠폰 사용 관련 문의</u>
            는 튜터링 App > 마이튜터링 > 이용안내 > 고객센터로 문의 바랍니다.
          </li>
          <li class="list__item">
            <u>쿠폰 발송 관련 문의</u>
            는 KB국민은행 고객센터(☎1588-9999)로 문의하시기 바랍니다.
          </li>
        </ul>
        <p class="title">[이벤트 유의사항]</p>
        <ul>
          <li class="list__item">
            본 이벤트는 KB스타뱅킹 App(최종 업데이트 버전)의 이벤트 페이지에서만 응모가 가능합니다.
          </li>
          <li class="list__item">
            쿠폰 발송일 기준 KB스타뱅킹 알림(Push) 및 ‘혜택알림 > 취향저격혜택’ 미동의 고객님은 쿠폰발송 대상에서
            제외됩니다.
          </li>
          <li class="list__item">
            본 이벤트 응모 및 튜터링 제휴쿠폰 발급(사용)은 KB스타뱅킹 고객 대상 1인 1회만 가능합니다. (쿠폰
            재발급/재사용 불가)
          </li>
          <li class="list__item">튜터링 제휴쿠폰은 KB국민은행에 등록된 고객정보상 연락처를 통해서 개별 발송됩니다.</li>
          <li class="list__item">
            본 이벤트는 KB국민은행 혹은 튜터링 내부 사정에 따라 변경 또는 중단될 수 있으며, 이벤트 경품은 사정상 동일
            금액대의 다른 상품으로 변경될 수 있습니다.
          </li>
          <li class="list__item">
            이벤트의 기타 자세한 내용은 KB국민은행 고객센터(☎1588-9999)로 문의하시거나 KB국민은행 홈페이지(
            <u>www.kbstar.com</u>
            )를 참조하시기 바랍니다.
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import vClickOutside from 'click-outside-vue3';
import inappTools from '@/common/inappTools';
import { device } from '@/tools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';

export default {
  name: 'KbStar',
  components: {
    AppNav,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        img_kbp_01: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_01.png',
        img_kbp_02: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_02.png',
        img_kbp_03: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_03.png',
        img_kbp_04: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_04.png',
        img_kbp_05: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_05.png',
      },
      bottomCtaUp: false,
      isMobileBottomSheetOpen: false,
      isOpenCollapse1: false,
      isOpenCollapse2: false,
    };
  },
  created() {},
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },
  methods: {
    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },
    downloadCoupon() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        inappTools.openWebBroswerNoPay('https://tinyurl.com/4f3y7dmj');
      } else {
        // 웹일 때
        const url = `https://tinyurl.com/4f3y7dmj`;
        window.location.href = url;
      }
    },
  },

  computed: {
    appView() {
      return inappTools.isInApp();
    },
    isMobile() {
      return device.isMobile();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped src="../../assets/newPromotions.scss"></style>
<style lang="scss" scoped>
.showOnlyMobile {
  display: none;
}
.showOnlyWeb {
  display: inline;
}
@media (max-width: 991px) {
  .showOnlyMobile {
    display: inline;
  }
  .showOnlyWeb {
    display: none;
  }
}
.main_section {
  background: #5d04d9;
  color: #ffffff;
  text-align: center;
  padding: 29px 0 37px 0;
  position: relative;
  img.img_kbp_01 {
    width: 287px;
    height: 102px;
    margin-bottom: 4px;
  }
  img.markCircle {
    position: absolute;
    left: 50%;
    bottom: -29px;
    transform: translate(-50%, 0%);
  }
}

.event_section {
  color: #333333;
  width: 310px;
  margin: 0 auto 100px auto;
  img.img_kbp_03 {
    width: 310px;
    height: 107px;
    margin-bottom: 40px;
  }
  .card {
    margin-bottom: 48px;
    .title {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 4px;
    }
    .desc_small {
      font-size: 12px;
      line-height: 17px;
      font-weight: 300;
    }
  }
  .coupon {
    text-align: center;
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin-bottom: 16px;
    }
    img {
      margin-bottom: 32px;
      width: 258px;
      height: 143px;
    }
    .button {
      cursor: pointer;
      background: #333333;
      border-radius: 53px;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #ffffff;
      padding: 12px 26px;
      margin-top: 20px;
    }
  }
  .qr {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 28px;
    padding: 45px 0 0 0;
    text-align: center;
    img {
      margin: 28px auto 0 auto;
    }
  }
}
.benefit_section {
  padding: 40px 0;
  background: #ffffff;
  text-align: center;
  img.img_kbp_02 {
    width: 217px;
    height: 169px;
    margin-bottom: 16px;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    margin-bottom: 16px;
  }
  .desc {
    font-weight: 700;
    font-size: 34px;
    color: #5d04d9;
    margin-bottom: 80px;
  }
  .benefit_card {
    margin-bottom: 64px;
    &_label {
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 4px;
      padding: 6px 16px;
      background: #5d04d9;
      border-radius: 53px;
      width: fit-content;
      margin: 0 auto 8px auto;
    }
    &_desc {
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      margin-bottom: 20px;
      line-height: 26px;
    }
  }
}
.notice_section {
  background: #ececec;
  padding: 60px 24px 40px 24px;
  color: #333333;
  .container {
    width: 312px;
    max-width: 960px;
    margin: 0 auto;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: 40px;
  }
  .list__item {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    text-indent: 0;
    &:before {
      content: '\B7';
      position: absolute;
      top: 0;
      left: -7px;
    }
  }
  .list__item_none {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    text-indent: 0;
  }
}
.modal__certificate {
  width: 320px !important;
  height: auto;
  padding: 40px 0 0 0;
  .common-modal__content {
    overflow-y: auto;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
  }
  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #666666;
    margin-bottom: 15px;
  }
  .card {
    background: #f8f8fa;
    border-radius: 10px;
    padding: 20px 25px;
    text-align: left;
    margin: 0 30px 25px 30px;
    .line {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      &:last-child {
        margin-bottom: 0;
      }
      .number {
        background: #000000;
        border-radius: 11px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
        padding: 1.4px 6px 0.5px 5px;
        margin-right: 8px;
      }
      .small_text {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

        color: #666666;
      }
    }
  }
  .notice {
    padding: 24px;
    text-align: left;
    background: #f1f0f5;
    border-radius: 0 0 10px 10px;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #3f3d44;
      margin-bottom: 10px;
      text-align: left;
    }
    .list__item {
      position: relative;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3f3d44;
      text-indent: 0;
      margin-top: 0;
      &:before {
        content: '\B7';
        position: absolute;
        top: 0;
        left: -7px;
      }
    }
  }
}
</style>
