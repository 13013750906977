import { render } from "./KbStar.vue?vue&type=template&id=0fe9024c&scoped=true"
import script from "./KbStar.vue?vue&type=script&lang=ts"
export * from "./KbStar.vue?vue&type=script&lang=ts"

import "../../assets/newPromotions.scss?vue&type=style&index=0&id=0fe9024c&lang=scss&scoped=true"
import "./KbStar.vue?vue&type=style&index=1&id=0fe9024c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0fe9024c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0fe9024c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0fe9024c', script)) {
    api.reload('0fe9024c', script)
  }
  
  module.hot.accept("./KbStar.vue?vue&type=template&id=0fe9024c&scoped=true", () => {
    api.rerender('0fe9024c', render)
  })

}

script.__file = "src/page/promotion/KbStar.vue"

export default script