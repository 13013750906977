
import vClickOutside from 'click-outside-vue3';
import inappTools from '@/common/inappTools';
import { device } from '@/tools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';

export default {
  name: 'KbStar',
  components: {
    AppNav,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        img_kbp_01: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_01.png',
        img_kbp_02: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_02.png',
        img_kbp_03: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_03.png',
        img_kbp_04: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_04.png',
        img_kbp_05: '//res.tutoring.co.kr/res/images/evt/230410/img_kbp_05.png',
      },
      bottomCtaUp: false,
      isMobileBottomSheetOpen: false,
      isOpenCollapse1: false,
      isOpenCollapse2: false,
    };
  },
  created() {},
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },
  methods: {
    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },
    downloadCoupon() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        inappTools.openWebBroswerNoPay('https://tinyurl.com/4f3y7dmj');
      } else {
        // 웹일 때
        const url = `https://tinyurl.com/4f3y7dmj`;
        window.location.href = url;
      }
    },
  },

  computed: {
    appView() {
      return inappTools.isInApp();
    },
    isMobile() {
      return device.isMobile();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },
  watch: {},
};
